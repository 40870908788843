import { lighten, saturate } from 'polished';
import React from 'react';
import styled from '@emotion/styled';

import { css } from '@emotion/core';

import { colors } from '../../styles/colors';
import config from '../../website-config';

import addToMailchimp from 'gatsby-plugin-mailchimp'

export class SubscribeForm extends React.Component {
  state = {
    email: null,
    style: {},
    error: null,
  }

  _handleChange = e => {
    console.log({
        [`${e.target.name}`]: e.target.value,
        })
    this.setState({
        [`${e.target.name}`]: e.target.value,
        })
  }

  _handleSubmit = async (e) => {
    e.preventDefault();
    const result = await addToMailchimp(this.state.email, this.state)


    if(result.result === "error") {
      this.setState({ error: result.msg });
      this.setState({style: {border: '1px solid red'}});

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        "event": "subscribeSubmit",
        "category": "subscribe button",
        "action": "click",
        "label": "false"
      });
    } else {
      this.setState({ error: null });
      this.setState({style: {}});
      this.props.completedSignup();

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        "event": "subscribeSubmit",
        "category": "subscribe button",
        "action": "click",
        "label": "true"
      });
    }

    // I recommend setting `result` to React state
    // but you can do whatever you want
  }

  render() {
    return (
      <form
        onSubmit={this._handleSubmit}
        noValidate
        css={SubscribeFormStyles}
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        className="subscribe-form"
        style={{alignItems:'start'}}
      >
        {/* This is required for the form to work correctly  */}
        <div style={{width:'100%'}}>
        <FormGroup className="form-group">
          <SubscribeEmail
            autocomplete="off"
            className="subscribe-email"
            name="email"
            type="email"
            placeholder="youremail@example.com"
            onChange={this._handleChange}
            style={this.state.style}
          />
        </FormGroup>
        <div style={{color:'red'}}>
          <div dangerouslySetInnerHTML={{__html: this.state.error}}></div>
        </div>
        </div>
        <SubscribeFormButton type="submit">
          <span>Subscribe</span>
        </SubscribeFormButton>
      </form>
    );
  }

};

const SubscribeFormStyles = css`
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 460px;

  @media (max-width: 500px) {
    flex-direction: column;

    .form-group {
      flex-direction: column;
      width: 100%;
    }
  }
`;

const SubscribeEmail = styled.input`
  display: block;
  padding: 10px;
  width: 100%;
  /* border: color(var(--lightgrey) l(+7%)) 1px solid; */
  border: ${lighten('0.07', colors.lightgrey)} 1px solid;
  /* color: var(--midgrey); */
  color: ${colors.midgrey};
  font-size: 1.8rem;
  line-height: 1em;
  font-weight: normal;
  user-select: text;
  border-radius: 5px;
  transition: border-color 0.15s linear;

  -webkit-appearance: none;

  :focus {
    outline: 0;
    /* border-color: color(var(--lightgrey) l(-2%)); */
    border-color: ${lighten('-0.02', colors.lightgrey)};
  }

  //@media (prefers-color-scheme: dark) {
    /* border-color: color(var(--darkmode) l(+6%)); */
    border-color: ${lighten('0.06', colors.darkmode)};
    color: rgba(255, 255, 255, 0.9);
    /* background: color(var(--darkmode) l(+3%)); */
    /*background: ${lighten('0.03', colors.darkmode)};*/
    background: #333;

    :focus {
      /* border-color: color(var(--darkmode) l(+25%)); */
      border-color: ${lighten('0.25', colors.darkmode)};
    }
  //}
`;

const SubscribeFormButton = styled.button`
  position: relative;
  display: inline-block;
  margin: 0 0 0 10px;
  padding: 0 20px;
  height: 44px;
  outline: none;
  color: #fff;
  font-size: 1.5rem;
  line-height: 39px;
  font-weight: 400;
  text-align: center;
  /* background: linear-gradient(
    color(var(--blue) whiteness(+7%)),
    color(var(--blue) lightness(-7%) saturation(-10%)) 60%,
    color(var(--blue) lightness(-7%) saturation(-10%)) 90%,
    color(var(--blue) lightness(-4%) saturation(-10%))
  ); */
  /* background: linear-gradient(
    ${lighten('0.07', colors.blue)},
    ${saturate('-0.1', lighten('-0.07', colors.blue))} 60%,
    ${saturate('-0.1', lighten('-0.07', colors.blue))} 90%,
    ${saturate('-0.1', lighten('-0.04', colors.blue))}
  ); */
  background: linear-gradient(#4fb7f0, #29a0e0 60%, #29a0e0 90%, #36a6e2);
  border-radius: 5px;

  -webkit-font-smoothing: subpixel-antialiased;

  :active,
  :focus {
    /* background: color(var(--blue) lightness(-9%) saturation(-10%)); */
    background: ${saturate('-0.1', lighten('-0.09', colors.blue))};
  }
  @media (max-width: 500px) {
    margin: 10px 0 0 0;
    width: 100%;
  }

  //@media (prefers-color-scheme: dark) {
    opacity: 0.9;
  //}
`;

const FormGroup = styled.div`
  @media (max-width: 500px) {
    width: 100%;
  }
`;

