import { lighten } from 'polished';

import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { colors } from './colors';

export const outer = css`
  position: relative;
  padding: 0 5vw;
`;

// Centered content container blocks
export const inner = css`
  margin: 0 auto;
  max-width: 1040px;
  width: 100%;
`;

export const SiteNavMain = css`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  /* background: color(var(--darkgrey) l(-5%)); */
  /* background: ${lighten('-0.05', colors.darkgrey)}; */

  //background: rgba(100, 100, 100, 0.8);


//-moz-box-shadow:inset 0 -20px 20px rgba(255, 255, 255, 0.02), 0 10px 50px rgba(0, 0, 0, 1);
 //-webkit-box-shadow:inset 0 -20px 20px rgba(255, 255, 255, 0.02), 0 10px 50px rgba(0, 0, 0, 1);
 //box-shadow:inset 0 -20px 20px rgba(255, 255, 255, 0.02), 0 10px 50px rgba(0, 0, 0, 1);
  //backdrop-filter: blur(2px);
  //background: rgba(25,25,25, 1);
  //box-shadow: 0 0 20px rgba(0, 0, 0, 1);


    background: rgba(0, 0, 0, 0.7);
    box-shadow:
      inset 0 -5px 25px rgba(150, 150, 150, .15), 0 10px 50px rgba(0, 0, 0, 1);;
    // border-bottom: 1px solid rgba(50, 50, 50, 1);;

`;

//import stars from '../content/img/stars.gif'

export const SiteMain = css`
 img {
    -webkit-user-select:none;
    -webkit-touch-callout:none;
 pointer-events: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
 }


  .youtube-link {
    color: #333 !important;
    font-family: Georgia, serif;
    font-size: 20px;
    box-shadow: none;
    transition: all 0.2s ease-in-out;
  }

  .youtube-link:hover {
    color: #333 !important;
    font-family: Georgia, serif;
    font-size: 20px;
    box-shadow: none;
    transition: all 0.2s ease-in-out;
  }

  .prev-next-link {
    color: #fff !important;
    font-family: Georgia, serif;
    font-size: 20px;
    box-shadow: inset 0 -1px 0 #fff;
    transition: all 0.2s ease-in-out;
  }

  .prev-next-link:hover {
    color: #26a6ed !important;
    box-shadow: inset 0 -1px 0 #26a6ed;
    text-decoration: none;
  }

  flex-grow: 1;

    //background: ${colors.darkmode};
  //background-image: url(${stars});

  .topic-description {
    color: #90a2aa !important;
  }

  dt {
    color: white !important;
    float: none;
    width: 100%;
    text-align:left;
  }

  dd {
   padding-bottom: 20px;
  }

`;

export const SiteTitle = styled.h1`
  z-index: 10;
  margin: 0 0 0 -2px;
  padding: 0;
  font-size: 5rem;
  line-height: 1em;
  font-weight: 600;
  text-align:center;
  white-space:break-spaces;
  text-shadow:1px 1px 10px rgba(62, 176, 239, 0.5), 1px 1px 10px rgba(255, 255, 255, 0.5);

  text-shadow:
     0 0 50px  rgba(255, 255, 255, 0.3),      /* inner white */
     2px 0 1px rgba(255, 255, 255, 0.3),   /* inner left magenta short */
     -2px 0 10px  rgba(255, 255, 255, 0.3),  /* inner right cyan short */
     2px 0 10px rgba(255, 255, 255, 0.3),  /* inner left magenta broad */
     -2px 0 10px rgba(255, 255, 255, 0.3); /* inner right cyan broad */

  width: 100%;
  @media (max-width: 500px) {
    font-size: 10vw;
  }
`;

export const BookLink = styled.a`

  z-index: 10;
  margin: 0;
  padding: 5px 0;
  font-size: 2.1rem;
  line-height: 1.4em;
  font-weight: 400;
  opacity: 0.8;
  text-align:center;

  @media (max-width: 500px) {
    font-size: 1.8rem;
  }

    /* color: var(--darkgrey); */
    color: rgba(255, 255, 255, 0.9);
    word-break: break-word;
    /* box-shadow: var(--darkgrey) 0 -1px 0 inset; */
    box-shadow: ${colors.lightgrey} 0 -1px 0 inset;
    transition: all 0.2s ease-in-out;

  :hover {
    /* color: var(--blue); */
    color: ${colors.blue};
    text-decoration: none;
    /* box-shadow: var(--blue) 0 -1px 0 inset; */
    box-shadow: ${colors.blue} 0 -1px 0 inset;
  }
`;

export const SiteDescription = styled.h2`
  z-index: 10;
  margin: 0;
  padding: 5px 0;
  font-size: 2.1rem;
  line-height: 1.4em;
  font-weight: 400;
  opacity: 0.8;
  //font-size: 16px;
  font-family: Georgia, Serif;

  @media (max-width: 500px) {
    font-size: 1.8rem;
  }
`;

export const SiteQuote = styled.q`
  z-index: 10;
  margin: 0;
  padding: 5px 0;
  font-size: 2.1rem;
  line-height: 1.4em;
  font-weight: 400;
  opacity: 0.8;

  @media (max-width: 500px) {
    font-size: 1.8rem;
  }
`;

export const Posts = css`
  //overflow-x: hidden;
`;

export const PostFeed = css`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
  padding: 50px 0 0;
  //background: #fff;

  /* Special Template Styles */
  padding: 40px 0 5vw;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  //@media (prefers-color-scheme: dark) {
    //background: ${colors.darkmode};
  //}
  //
`;

export const SocialLink = css`
  display: inline-block;
  margin: 0;
  padding: 10px;
  opacity: 0.8;

  :hover {
    opacity: 1;
  }

  svg {
    height: 1.8rem;
    fill: #fff;
  }
`;

export const SocialLinkFb = css`
  svg {
    height: 1.6rem;
  }
`;

export const SiteHeader = css``;

export const SiteHeaderContent = styled.div`
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6vw 3vw;
  min-height: 200px;
  //max-height: 340px;
  margin-top: 84px;
`;

export const SiteHeaderStyles = css`
  position: relative;
  /* margin-top: 64px; */
  padding-bottom: 12px;
  color: #fff;
  /* background: color(var(--darkgrey) l(-5%)) no-repeat center center; */
  /* background: ${lighten('-0.05', colors.darkgrey)} no-repeat center center; */
  background-size: cover;

  :before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: block;
    background: rgba(0, 0, 0, 0.18);
  }
  :after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: auto;
    left: 0;
    z-index: 10;
    display: block;
    height: 140px;
    background: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
  }

  //@media (prefers-color-scheme: dark) {
    :before {
      background: rgba(0, 0, 0, 0.6);
    }
  //}
  //
  //p
  //
`;

export const AuthorProfileImage = css`
  flex: 0 0 60px;
  margin: 0;
  width: 60px;
  height: 60px;
  border: none;

  //@media (prefers-color-scheme: dark) {
    box-shadow: 0 0 0 6px hsla(0, 0%, 100%, 0.04);
    background: ${colors.darkmode};
  //}
`;

// tag and author post lists
export const SiteArchiveHeader = css`
  .site-header-content {
    position: relative;
    align-items: stretch;
    padding: 12vw 0 20px;
    min-height: 200px;
    max-height: 600px;
  }
`;

export const SiteHeaderBackground = css`
  margin-top: 64px;
`;

export const ResponsiveHeaderBackground = styled.div<{ backgroundImage?: string }>`
  ${p =>
    p.backgroundImage &&
    `
    position: relative;
    margin-top: 64px;
    padding-bottom: 12px;
    color: #fff;
    background-size: cover;
    /* background: color(var(--darkgrey) l(-5%)) no-repeat center center; */
    background: #090a0b no-repeat 50%;
    background-image: url(${p.backgroundImage});

    :before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 10;
      display: block;
      background: rgba(0, 0, 0, 0.18);
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: auto;
      left: 0;
      z-index: 10;
      display: block;
      height: 140px;
      background: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
    }

    //@media (prefers-color-scheme: dark) {
      &:before {
        background: rgba(0, 0, 0, 0.6);
      }
    //}
  `}

  ${p =>
    !p.backgroundImage &&
    `

    padding-top: 0;
    padding-bottom: 0;
    /* color: var(--darkgrey); */
    color: ${colors.darkgrey};
    background: #fff;
    opacity: 1;


  .site-description {
    /* color: var(--midgrey); */
    color: ${colors.midgrey};
    opacity: 0.7;
  }

  .topic-description {
    /* color: var(--midgrey); */
    //color: ${colors.midgrey};
    color: #90a2aa !important
    opacity: 0.7;
  }

  .site-quote {
    /* color: var(--midgrey); */
    color: ${colors.midgrey};
    opacity: 1;
    font-style: italic;
  }

  .site-header-content {
    padding: 5vw 0 10px;
    /* border-bottom: 1px solid color(var(--lightgrey) l(+12%)); */
    border-bottom: 1px solid ${lighten('0.12', colors.lightgrey)};
  }

  .author-bio {
    /* color: var(--midgrey); */
    color: ${colors.midgrey};
    opacity: 1;
  }

  .author-meta {
    /* color: var(--midgrey); */
    color: ${colors.midgrey};
    opacity: 1;
  }

  .author-social-link a {
    /* color: var(--darkgrey); */
    color: ${colors.darkgrey};
  }

  .author-social-link a:before {
    /* color: var(--midgrey); */
    color: ${colors.midgrey};
  }

  .author-location + .author-stats:before,
  .author-stats + .author-social-link:before,
  .author-social-link + .author-social-link:before {
    /* color: var(--midgrey); */
    color: ${colors.midgrey};
  }

  .author-header {
    padding-bottom: 20px;
  }

  @media (max-width: 500px) {
    .site-header-content {
      flex-direction: column;
      align-items: center;
      min-height: unset;
    }

    .site-title {
      font-size: 4.2rem;
      text-align: center;
    }

    .site-header-content {
      padding: 12vw 0 20px;
    }

    .author-header {
      padding-bottom: 10px;
    }
  }
  //@media (prefers-color-scheme: dark) {
    color: rgba(255, 255, 255, 0.9);
    /* background: var(--darkmode); */
    background: ${colors.darkmode};

    .site-header-content {
      /* border-bottom-color: color(var(--darkmode) l(+15%)); */
      //border-bottom-color: ${lighten('0.15', colors.darkmode)};
      border: 0;
    }

    .author-social-link a {
      color: rgba(255, 255, 255, 0.75);
    }
  //}
  `}
`;


export const NoImage = css`
  .no-image {
    padding-top: 0;
    padding-bottom: 0;
    /* color: var(--darkgrey); */
    color: ${colors.darkgrey};
    background: #fff;
    opacity: 1;
  }

  .no-image .site-description {
    /* color: var(--midgrey); */
    color: ${colors.midgrey};
    opacity: 1;
  }

  .no-image .topic-description {
    /* color: var(--midgrey); */
    //color: ${colors.midgrey};
    color: #90a2aa !important
    opacity: 1;
  }

  .no-image .site-quote {
    font-style: italic;
    /* color: var(--midgrey); */
    //color: ${colors.midgrey};
    opacity: 1;
  }

  .no-image .site-header-content {
    padding: 5vw 0 10px;
    /* border-bottom: 1px solid color(var(--lightgrey) l(+12%)); */
    border-bottom: 1px solid ${lighten('0.12', colors.lightgrey)};
  }

  .no-image .author-bio {
    /* color: var(--midgrey); */
    color: ${colors.midgrey};
    opacity: 1;
  }

  .no-image .author-meta {
    /* color: var(--midgrey); */
    color: ${colors.midgrey};
    opacity: 1;
  }

  .no-image .author-social-link a {
    /* color: var(--darkgrey); */
    color: ${colors.darkgrey};
  }

  .no-image .author-social-link a:before {
    /* color: var(--midgrey); */
    color: ${colors.midgrey};
  }

  .no-image .author-location + .author-stats:before,
  .no-image .author-stats + .author-social-link:before,
  .no-image .author-social-link + .author-social-link:before {
    /* color: var(--midgrey); */
    color: ${colors.midgrey};
  }

  @media (max-width: 500px) {
    .site-header-content {
      flex-direction: column;
      align-items: center;
      min-height: unset;
    }

    .site-title {
      font-size: 4.2rem;
      text-align: center;
    }

    .no-image .site-header-content {
      padding: 12vw 0 20px;
    }
  }
  //@media (prefers-color-scheme: dark) {
    .no-image {
      color: rgba(255, 255, 255, 0.9);
      /* background: var(--darkmode); */
      background: ${colors.darkmode};
    }

    .no-image .site-header-content {
      /* border-bottom-color: color(var(--darkmode) l(+15%)); */
      //border-bottom-color: ${lighten('0.15', colors.darkmode)};
      border: 0;
    }

    .no-image .author-social-link a {
      color: rgba(255, 255, 255, 0.75);
    }
  //}
`;
