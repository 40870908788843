const config = {
  title: 'The Empty Robot',
  description: 'The Empty Robot aims to explore what it means to experience life by contemplating topics such as the nature of being, emptiness, the self and illusion of self, determinism and free will, the cause of suffering and the end of suffering, space and time, technology, mindfulness, and more.',
  coverImage: 'img/space-nebula.png',
  logo: 'img/emptyrobot-background.png',
  lang: 'en',
  siteUrl: 'https://theemptyrobot.com',
  facebook: 'https://www.facebook.com/theemptyrobot',
  twitter: 'https://twitter.com/theemptyrobot',
  instagram: 'https://instagram.com/theemptyrobot',
  showSubscribe: true,
  googleSiteVerification: 'GoogleCode',
  footer: '',
};

module.exports =  config;
